import React from 'react';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Col } from 'reactstrap'
import '../customStyles.css'
import DesktopBreakpoint from '../responsive/desktop_breakpoint'
import TabletBreakpoint from '../responsive/tablet_breakpoint'
import PhoneBreakpoint from '../responsive/phone_breakpoint'
import AgentMobileContainer from './AgentMobileContainer';

const AgentStatusTable = props => {

    const statusFormat = (cell) => {
        //if (cell === "Available") return "agentAvailable";
        //if (cell === "On Call") return "agentOnCall";
        return "agentStatusWrap";
    }

    const statusDataFormat = (cell) => {
        if (cell === "Available") return `<div class="agentAvailable">${cell}</div>`;
        if (cell === "On Call") return `<div class="agentOnCall">${cell}</div>`;
        return `<div class="agentOther">${cell}</div>`;
    }

    function AgentTable(props) {
        return (
            <BootstrapTable data={props.data} bordered={false}>
                <TableHeaderColumn width="30%" dataField="name" headerAlign="left" isKey={true} className={props.headerClass} columnClassName={props.rowClass}>
                    Name </TableHeaderColumn>
                <TableHeaderColumn width="15%" dataField="status" dataFormat={statusDataFormat} headerAlign="center" dataAlign="center" className={props.headerClass} columnClassName={statusFormat}>
                    Status</TableHeaderColumn>
                <TableHeaderColumn width="12%" dataField="calls" headerAlign="right" dataAlign="right" className={props.headerClass} columnClassName={props.rowClass}>
                    Calls </TableHeaderColumn>
                <TableHeaderColumn dataField="averageHandle" headerAlign="right" dataAlign="right" className={props.headerClass} columnClassName={props.rowClass}>
                    Average Handle </TableHeaderColumn>
                <TableHeaderColumn width="18%" dataField="talkTime" headerAlign="right" dataAlign="right" className={props.headerClass} columnClassName={props.rowClass}>
                    Talk Time </TableHeaderColumn>
            </BootstrapTable>
    );
    }

    return (
        <div>
            <DesktopBreakpoint>
                <div class="agentTableWrap">
                    <Col className={"tableTitle"}>{props.title}</Col>
                    <AgentTable data={props.data} headerClass="blueColHeader" rowClass="agentDataRow" />
                </div>
            </DesktopBreakpoint>
            <TabletBreakpoint>
                <div class="agentTableWrap">
                    <Col className="tableTitleMobile">{props.title}</Col>
                    <AgentTable data={props.data} headerClass="blueColHeaderTablet" rowClass="agentDataRowTablet" />
                </div>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Col className="tableTitleMobile">{props.title}</Col>
                <div>
                    {props.data.map((agent, index) => (
                        <div key={index}>
                            <AgentMobileContainer agentData={agent} />
                        </div>
                    ))}
                </div>
            </PhoneBreakpoint>
        </div>
    );
}

export default AgentStatusTable;