import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import Timer from './Timer.js'
import '../customStyles.css'

const CallMobileContainer = props => {

    const countUp = (qTime) => {
        return <Timer ts={qTime} />;
    }

    return (
        <Container className="mobileDataContainer" fluid>
            <Row>
                <Col xs="12" className="mobileCallerInfo">{props.callData.name}</Col>
            </Row>
            <Row>
                <Col xs="7" className="mobileCallNum">{props.callData.callNumber}</Col>
                <Col xs="2" className="mobileCallWait">Wait: </Col>
                <Col xs="3" className="mobileCallWait">{countUp(props.callData.waitTime)}</Col>
            </Row>
        </Container>
    );
}

export default CallMobileContainer;