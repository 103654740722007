import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import '../customStyles.css'

const AgentMobileContainer = props => {

    const statusFormat = (stat) => {
        if (stat === "Available") return "agentAvailableMobile";
        if (stat === "On Call") return "agentOnCallMobile"
        return "agentOtherMobile";
    }

    return (
        <Container className="mobileDataContainer" fluid>
            <Row>
                <Col xs="12" className={statusFormat(props.agentData.status)}>{props.agentData.name}</Col>
            </Row>
            <Row>
                <Col xs="3" className="mobileHeader">Calls</Col>
                <Col xs="5" className="mobileHeader">Avg. Handle</Col>
                <Col xs="4" className="mobileHeader">Talk Time</Col>
            </Row>
            <Row>
                <Col xs="3" className="mobileData">{props.agentData.calls}</Col>
                <Col xs="5" className="mobileData">{props.agentData.averageHandle}</Col>
                <Col xs="4" className="mobileData">{props.agentData.talkTime}</Col>
            </Row>
        </Container>
    );
}

export default AgentMobileContainer;