import React from 'react';
import DesktopBreakpoint from './responsive/desktop_breakpoint'
import TabletBreakpoint from './responsive/tablet_breakpoint'
import PhoneBreakpoint from './responsive/phone_breakpoint'
import Image from 'react-bootstrap/Image'
import mainBar from './images/MainBar.png';
import mainBarTablet from './images/MainBarTablet.png';
import mainBarPhone from './images/MainBarMobile.png';

const MainLogo = () => {
    return (
        <div class="mainLogo">
            <DesktopBreakpoint>
                <Image src={mainBar} width="100%"/>
            </DesktopBreakpoint>
            <TabletBreakpoint>
                <Image src={mainBarTablet} width="100%"/>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Image src={mainBarPhone} width="100%"/>
            </PhoneBreakpoint>
        </div>
    );
}

export default MainLogo;