import React, { Component } from 'react';
import MainLogo from './MainLogo';
import { NavMenu } from './NavMenu';
//import { NavMenu } from './NavMenu';  -- add <NavMenu /> inside initial <div>

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <div class="layoutWrapper">
            <div class="layoutTopNav"><MainLogo /></div>
            <div class="layoutNavMenu"><NavMenu /></div>
            <div class="layoutContent">{this.props.children}</div>
            <div class="layoutFooter"></div>
        </div>
    );
  }
}
