import React from 'react';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Col } from 'reactstrap'
import '../customStyles.css'
import DesktopBreakpoint from '../responsive/desktop_breakpoint'
import TabletBreakpoint from '../responsive/tablet_breakpoint'
import PhoneBreakpoint from '../responsive/phone_breakpoint'
import AgentMobileContainer from './AgentMobileContainer';

const AgentStatusTableReporting = props => {


    function AgentTable(props) {
        return (
            <BootstrapTable data={props.data} bordered={false}>
                <TableHeaderColumn width="30%" dataField="Agent" headerAlign="left" isKey={true} className={props.headerClass} columnClassName={props.rowClass}>
                    Name </TableHeaderColumn>
                <TableHeaderColumn width="20%" dataField="Average Handle Time (Minutes)" headerAlign="center" dataAlign="center" className={props.headerClass} columnClassName={props.rowClass}>
                    Avg. Handled Time</TableHeaderColumn>
                <TableHeaderColumn width="20%" dataField="Calls Handled" headerAlign="right" dataAlign="right" className={props.headerClass} columnClassName={props.rowClass}>
                    Calls Handled </TableHeaderColumn>
                <TableHeaderColumn width="18%" dataField="Total Talk Time (Minutes)" headerAlign="right" dataAlign="right" className={props.headerClass} columnClassName={props.rowClass}>
                    Total Talk Time </TableHeaderColumn>
            </BootstrapTable>
    );
    }

    return (
        <div>
            <DesktopBreakpoint>
                <div class="agentTableWrap">
                    <Col className={"tableTitle"}>{props.title}</Col>
                    <AgentTable data={props.data} headerClass="blueColHeader" rowClass="agentDataRow" />
                </div>
            </DesktopBreakpoint>
            <TabletBreakpoint>
                <div class="agentTableWrap">
                    <Col className="tableTitleMobile">{props.title}</Col>
                    <AgentTable data={props.data} headerClass="blueColHeaderTablet" rowClass="agentDataRowTablet" />
                </div>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Col className="tableTitleMobile">{props.title}</Col>
                <div>
                    {props.data.map((agent, index) => (
                        <div key={index}>
                            <AgentMobileContainer agentData={agent} />
                        </div>
                    ))}
                </div>
            </PhoneBreakpoint>
        </div>
    );
}

export default AgentStatusTableReporting;