import React, { Component } from 'react';
import { Button, Container, Row, Col, InputGroup, Input } from 'reactstrap'
import { httpAuth } from './axios';
import QueueStatsReportingContainer from './MainDashboard/QueueStatsReportingContainer';
import AgentStatusTableReporting from './MainDashboard/AgentStatusTableReporting';
import SummaryChatStatsContainer from './MainDashboard/SummaryChatStatsContainer';

export class HistoricalView extends Component {
    static displayName = HistoricalView.name;

  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      abandonedCalls: 0,
      abandonRate: 0,
      callsHandled: 0,
      callsOffered: 0,
      totalTalkTime: 0,
      holdTime: 0,
      chatsOffered: 0,
      chatsHandled: 0,
      missedChats: 0,
      totalChatTime: 0,
      statisfaction: 0,
      surveysTaken: 0,
      averageHandleTime: 0,
      loadingSummaryStats: true,
      loadingAgents: true,
      loadingSummaryChatStats: true,
      agents: [],
      clientConfig: {
        slThreshold: 0,
        arThreshold: 0,
        awtThreshold: 0,
        attThreshold: 0
    }
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleClick = () => {
    //do something
    //console.log(this.state);
    this.getSummaryStats();
    this.getAgentStats();
    this.getSummaryChatStats();
  }


  async getSummaryStats() {
    
    let config = {
      method: 'get',
      url: '/api/summarystats',
      params: {
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }
    }
    const response = await httpAuth(config);
    //console.log(response);
    this.setState({
      abandonedCalls: response.data[0]["Abandoned Calls"],
      callsHandled: response.data[0]["Calls Handled"],
      callsOffered: response.data[0]["Calls Offered"],
      abandonRate: response.data[0]["Abandonment %"],
      totalTalkTime: response.data[0]["Total Talk Time"],
      holdTime: response.data[0]["Hold Time"],
      averageHandleTime: response.data[0]["Average Handle Time"],
      loadingSummaryStats: false
    });
  }

  async getSummaryChatStats() {
    
    let config = {
      method: 'get',
      url: '/api/getSummaryChatStats',
      params: {
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }
    }
    const response = await httpAuth(config);
    console.log(response);
    this.setState({
      chatsOffered: response.data[0]["Chats_Offered"],
      chatsHandled: response.data[0]["Chats Handled"],
      missedChats: response.data[0]["Missed_Chats"],
      totalChatTime: response.data[0]["Total_Chat_Time"],
      satisfaction: response.data[0]["Satisfaction"],
      surveysTaken: response.data[0]["Surveys_Taken"],
      loadingSummaryChatStats: false
    });
  }

  async getAgentStats() {
    
    let config = {
      method: 'get',
      url: '/api/agentdaterangestats',
      params: {
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }
    }
    const response = await httpAuth(config);
    this.setState({
      agents: response.data,
      loadingAgents: false
    });
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <InputGroup>
              <Input placeholder="Start Date" name="startDate" onChange={this.handleChange} type="date" />&nbsp;&nbsp;
              <Input placeholder="End Date" name="endDate" onChange={this.handleChange} type="date" />&nbsp;&nbsp;
              <Button color="primary" onClick={this.handleClick}>View Results</Button>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
              {
                  this.state.loadingSummaryStats
                      ? <></>
                      : <QueueStatsReportingContainer
                          callVolume={this.state.callsOffered}
                          callsHandled={this.state.callsHandled}
                          abandonedCalls={this.state.abandonedCalls}
                          totalTalkTime={this.state.totalTalkTime}
                          holdTime={this.state.holdTime}
                          abandonRate={this.state.abandonRate}
                          avgHandleTime={this.state.averageHandleTime}
                          clientConfig={this.state.clientConfig} />
              }
          </Col>
        </Row>
        <Row>
        <Col lg="12" md="12">
              {
                  this.state.loadingAgents
                      ? <></>
                      : <AgentStatusTableReporting title="Agent Status" data={this.state.agents} />
              }
        </Col>
        </Row>
        <Row>
                    <Col>
                        {
                          this.state.loadingSummaryChatStats
                            ? <></>
                            : <SummaryChatStatsContainer 
                                chatsOffered={this.state.chatsOffered} 
                                chatsHandled={this.state.chatsOffered}
                                missedChats={this.state.missedChats}
                                totalChatTime={this.state.totalChatTime}
                                satisfaction={this.state.satisfaction}
                                surveysTaken={this.state.surveysTaken}/>
                        }
                    </Col>
                </Row>
      </Container>
    );
  }
}
