import React from 'react';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Col } from 'reactstrap'
import Timer from './Timer.js'
import '../customStyles.css'
import DesktopBreakpoint from '../responsive/desktop_breakpoint'
import TabletBreakpoint from '../responsive/tablet_breakpoint'
import PhoneBreakpoint from '../responsive/phone_breakpoint'
import CallMobileContainer from './CallMobileContainer';

const CallQueueTable = props => {

    const countUp = (cell) => {
        return <Timer ts={cell} />;
    }

    function CallTable(props) {
        return (
            <BootstrapTable data={props.data} bordered={false}>
                <TableHeaderColumn dataField="id" isKey hidden={true}>
                    Id </TableHeaderColumn>
                <TableHeaderColumn width="40%" dataField="name" headerAlign="left" className={props.headerClass} columnClassName={props.rowClass}>
                    Name </TableHeaderColumn>
                <TableHeaderColumn width="25%" dataField="callNumber" headerAlign="center" dataAlign="center" className={props.headerClass} columnClassName={props.rowClass}>
                    Call Number </TableHeaderColumn>
                <TableHeaderColumn width="15%" dataField="status" headerAlign="left" dataAlign="left" className={props.headerClass} columnClassName={props.rowClass}>
                    Status </TableHeaderColumn>
                <TableHeaderColumn width="20%" dataField="waitTime" headerAlign="right" dataAlign="right" className={props.headerClass} columnClassName={props.rowClass}
                    dataFormat={countUp}>
                    Wait Time </TableHeaderColumn>
            </BootstrapTable>
        );
    }

    return (
        <div class="callQueueWrap">
            <DesktopBreakpoint>
                <div class="callTableWrap">
                    <Col className={"tableTitle"}>{props.title}</Col>
                    <CallTable data={props.data} headerClass="blueColHeader" rowClass="agentDataRow" />
                </div>
            </DesktopBreakpoint>
            <TabletBreakpoint>
                <div class="callTableWrap">
                    <Col className={"tableTitleMobile"}>{props.title}</Col>
                    <CallTable data={props.data} headerClass="blueColHeaderTablet" rowClass="agentDataRowTablet"/>
                </div>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Col className={"tableTitleMobile"}>{(props.data.length > 0 ? props.title : "")}</Col>
                <div>
                    {props.data.map((call, index) => (
                        <div key={index}>
                            <CallMobileContainer callData={call} />
                        </div>
                    ))}
                </div>
            </PhoneBreakpoint>
        </div>
    );    
}

export default CallQueueTable;