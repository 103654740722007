import React from 'react';
import { Col } from 'reactstrap'
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import '../customStyles.css'

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const StatisticContainer = props => {

    return (
        <Col lg={props.span} className={props.className}>
            <MuiThemeProvider theme={theme}>
                <Typography variant={props.headerVar} align="center" noWrap={true} style={{ fontWeight: "bold", color: props.headerColor }}>{props.header}</Typography>
                <Typography variant={props.valueVar} align="center" style={{ fontWeight: "bold" }}>{props.value}</Typography>
            </MuiThemeProvider>
        </Col>
    );
}

export default StatisticContainer;