import React, { Component } from 'react';
import axios from 'axios';
import { httpAuth } from './axios.js';
import { Container, Row, Col } from 'reactstrap'
import CallQueueTable from './MainDashboard/CallQueueTable';
import AgentStatusTable from './MainDashboard/AgentStatusTable';
import QueueStatsContainer from './MainDashboard/QueueStatsContainer';
import DashboardEventListener from './MainDashboard/EventListeners/DashboardEventListener';
import ChatStatsContainer from './MainDashboard/ChatStatsContainer';
import './customStyles.css'

export class Home extends Component {
    static displayName = Home.name;
    dailyRefreshTimeoutHandle;

    constructor(props) {
        super(props);
        this.state = {
            agents: [],
            queuedCalls: [],
            callVolume: 0,
            callsHandled: 0,
            abandonedCalls: 0,
            serviceLevel: 0.0,
            abandonRate: 0.0,
            avgWaitTimeString: "0:00",
            avgTalkTimeString: "0:00",
            avgWaitTime: 0.0,
            avgTalkTime: 0.0,
            loadingAgents: true,
            loadingQueuedCalls: true,
            loadingQueueStats: true,
            clientConfig: {
                slThreshold: 0,
                arThreshold: 0,
                awtThreshold: 0,
                attThreshold: 0
            },
            loadingChatStats: true,
            chatStats: {}
        };
    }

    componentDidMount() {
        this.getClientConfig();

        this.loadAllData();
    }

    componentWillUnmount() {
        clearTimeout(this.dailyRefreshTimeoutHandle);
    }

    loadAllData = () => {
        this.populateAgentData()
            .then(() => {
                this.populateQueuedCallData();
                this.populateQueueStats();
            });
        this.populateChatStats();

        clearTimeout(this.dailyRefreshTimeoutHandle);

        var now = new Date();
        var midnight = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1,
            0, 0, 0);

        var msUntilMidnight = (midnight.getTime() - now.getTime()) + 5000; // +5 seconds of padding

        console.log('Page data will automatically refresh in ' + (msUntilMidnight / 1000) + 'seconds. ', new Date().toISOString())
        console.log('Page loaded successfully');

        this.dailyRefreshTimeoutHandle = setTimeout(() => 
            this.loadAllData(), msUntilMidnight
        );
    }

    async getClientConfig() {

        let config = {
            method: 'get',
            url: '/clientconfig',
        }
        const response = await axios(config);

        var tempConfig = this.state.clientConfig;
        tempConfig.slThreshold = response.data.slThreshold;
        tempConfig.arThreshold = response.data.arThreshold;
        tempConfig.awtThreshold = response.data.awtThreshold;
        tempConfig.attThreshold = response.data.attThreshold;

        this.setState({ clientConfig: tempConfig });
    }

    async populateAgentData() {

        let config = {
            method: 'post',
            url: '/api/agentstats',
        }
        const response = await httpAuth(config); 
        console.log(response);

        this.setState({ agents: response.data, loadingAgents: false });
        console.log("Agent Data Loaded. ", new Date().toISOString());
    }

    async populateQueuedCallData() {

        let config = {
            method: 'post',
            url: '/api/activecalls',
        }
        const response = await httpAuth(config);

        if (response.status !== 200) {
            this.setState({ queuedCalls: [], loadingQueuedCalls: false });
        }
        else {
            this.setState({ queuedCalls: response.data, loadingQueuedCalls: false });
        }
        console.log("Queued Call Data Loaded. ", new Date().toISOString());
    }

    async populateQueueStats() {

        let config = {
            method: 'post',
            url: '/api/queuestats'
        }
        const response = await httpAuth(config);

        this.setState({
            callVolume: response.data.vol,
            callsHandled: response.data.ch,
            abandonedCalls: response.data.ac,
            serviceLevel: response.data.sl,
            abandonRate: response.data.ar,
            avgWaitTimeString: response.data.avgWaitTimeString,
            avgTalkTimeString: response.data.avgTalkTimeString,
            avgWaitTime: response.data.awt,
            avgTalkTime: response.data.att,
            loadingQueueStats: false
        });
        console.log("Queue Stats Loaded. ", new Date().toISOString());
    }

    async populateChatStats() {
        let config = {
            method: 'get',
            url: '/api/chatstats'
        }
        const response = await httpAuth(config);

        this.setState({
            chatStats: response.data,
            loadingChatStats: false
        });
        console.log("Chat Stats Loaded. ", new Date().toISOString());
    }
    updateCalls = (calls) => {
        this.setState({ queuedCalls: calls });
    }

    updateAgents = (agentStatuses) => {
        this.setState({ agents: agentStatuses })
    }

    refreshStats = () => {
        this.populateAgentData()
            .then(() => {
                this.populateQueueStats();
            });
    }

    refreshChatStats = (cs) => {
        this.setState({ chatStats: cs });
    };

    render() {
        return (
            <Container fluid>
                <DashboardEventListener reconnectHandler={this.loadAllData} onCallsUpdated={this.updateCalls} onAgentsUpdated={this.updateAgents} onCallEnded={this.refreshStats} onChatStatsUpdated={this.refreshChatStats}/>
                <Row>
                    <Col>
                        {
                            this.state.loadingQueueStats
                                ? <p><em>Loading Statistics...</em></p>
                                : <QueueStatsContainer
                                    callVolume={this.state.callVolume}
                                    callsHandled={this.state.callsHandled}
                                    abandonedCalls={this.state.abandonedCalls}
                                    serviceLevel={this.state.serviceLevel}
                                    abandonRate={this.state.abandonRate}
                                    avgWaitTimeString={this.state.avgWaitTimeString}
                                    avgTalkTimeString={this.state.avgTalkTimeString}
                                    avgWaitTime={this.state.avgWaitTime}
                                    avgTalkTime={this.state.avgTalkTime}
                                    clientConfig={this.state.clientConfig} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="12">
                        {
                            this.state.loadingAgents
                                ? <p><em>Loading Agents...</em></p>
                                : <AgentStatusTable title="Agent Status" data={this.state.agents} />
                        }
                    </Col>
                    <Col lg="6" md="12">
                        {
                            this.state.loadingQueuedCalls
                                ? <p><em>Loading Call Queue...</em></p>
                                : <CallQueueTable title="Calls Waiting" data={this.state.queuedCalls} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.loadingChatStats
                            ? <p><em>Loading Chat Statistics...</em></p>
                            : <ChatStatsContainer stats={this.state.chatStats} />
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}
