
import axios from 'axios';
import {Auth} from "@aws-amplify/auth";

/**
 * httpAuth axios instance sends request with authentication token, 
 * exponential backoff retry logic (default of 5 retries starting at 1s),
 * and automatic token refresh in the event of a 401/403 error
 */
export const httpAuth = axios.create({
   // baseURL: add if necessary
});

httpAuth.interceptors.request.use(request => {
    return requestHandler(request)
})

httpAuth.interceptors.response.use(response => {
    return response
},
    error => {
        return retryError(error)
    })

const requestHandler = async (request) => {
    // Amplify will refresh the token if expired automatically
    // when you grab the current session if there is a refresh token
    const session = await Auth.currentSession();
    const accessToken = session.accessToken.jwtToken;

    // if we do not already have the auth header, add it now
    if (!request.headers.Authorization) {
        request.headers.Authorization = `Bearer ${accessToken}`;
    }
    
    if (!request.retry) {
        request.retry = 5;
        request.retryDelay = 1000;
    }

    return request
};


const retryError = error => {
    const { config } = error;

    console.log("ERROR: " + Date.now().toString());

    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) return Promise.reject(error);

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
        // Reject with the error
        return Promise.reject(error);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Calculate delay for this attempt
    const backOffDelay = config.retryDelay
        ? ((1 / 2) * (Math.pow(2, config.__retryCount) - 1)) * 1000
        : 1;

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function (resolve) {
        setTimeout(function () {
            resolve();
        }, backOffDelay);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
        return httpAuth(config);
    });
}
