import React from 'react';
import { Row, Col } from 'reactstrap'
import StatisticContainer from './StatisticContainer';
import DesktopBreakpoint from '../responsive/desktop_breakpoint'
import TabletBreakpoint from '../responsive/tablet_breakpoint'
import PhoneBreakpoint from '../responsive/phone_breakpoint'
import '../customStyles.css'
import { useState, useEffect } from 'react';

const QueueStatsReportingContainer = props => {

    //const [totalTalkTime] = useState(props.totalTalkTime);
    var nf = new Intl.NumberFormat();

    // const getFormattedTime = () => {
    //    console.log(totalTalkTime);
    //     return millisecondsToTimer(totalTalkTime)
    // };

    // const millisecondsToTimer = (ms) => {
    //     // 1- Convert to seconds:
    //     var seconds = ms / 1000;

    //     // 2- Extract hours:
    //     var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour

    //     seconds = seconds % 3600; // seconds remaining after extracting hours

    //     // 3- Extract minutes:
    //     var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute

    //     // 4- Keep only seconds not extracted to minutes:
    //     seconds = seconds % 60;

    //     return ((hours > 0 ? hours + ":" : "")
    //         + (hours > 0 && minutes < 10 ? "0" : "") + minutes + ":"
    //         + (seconds < 10 ? "0" : "") + Math.floor(seconds));
    // }

    return (
        <div class="queueStatsWrap">
            <DesktopBreakpoint>
                <Row>
                    <Col lg="5">
                        <Row className="d-flex justify-content-around">
                            <StatisticContainer headerVar="h6" valueVar="h2" span="4" className="statsRegular" headerColor="#004270" header="Call Volume" value={nf.format(props.callVolume)} />
                            <StatisticContainer headerVar="h6" valueVar="h2" span="4" className="statsRegular" headerColor="#004270" header="Calls Handled" value={nf.format(props.callsHandled)} />
                            <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Abandoned" value={nf.format(props.abandonedCalls)} />
                        </Row>
                    </Col>
                    <Col lg="7">
                        <Row>
                            <Col lg="6">
                                <Row className="d-flex justify-content-around">
                                    <StatisticContainer
                                        span="5" headerVar="h6" valueVar="h2" 
                                        className={props.abandonRate < props.clientConfig.arThreshold ? "statsPositive" : "statsNegative"}
                                        headerColor="white"
                                        header="Abandonment %" value={props.abandonRate} />
                                          <StatisticContainer
                                        span="6" headerVar="h6" valueVar="h2" 
                                        className={props.avgTalkTime <= props.clientConfig.attThreshold ? "statsPositive" : "statsNegative"}
                                        headerColor="white"
                                        header="Avg. Handle Time" value={props.avgHandleTime} />
                                </Row>
                            </Col>
                            <Col lg="6">
                                <Row className="d-flex justify-content-around">
                                    <StatisticContainer headerVar="h6" valueVar="h2" span="5" className="statsRegular" headerColor="#004270" header="Hold Time" value={props.holdTime} />
                                    <StatisticContainer headerVar="h6" valueVar="h3" span="6" className="statsRegular" headerColor="#004270" header="Total Talk Time" value={props.totalTalkTime} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </DesktopBreakpoint>
            <TabletBreakpoint>            
                <Row className="d-flex justify-content-around">
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="darkblue" header="Call Volume" value={props.callVolume} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="darkblue" header="Calls Handled" value={props.callsHandled} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="darkblue" header="Abandoned" value={props.abandonedCalls} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="darkblue" header="Total Talk Time" value={props.totalTalkTime} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="darkblue" header="Hold Time" value={props.holdTime} />
                </Row>
                <Row className="d-flex justify-content-around">
                    <StatisticContainer
                        span="4" headerVar="h6" valueVar="h2" 
                        className={props.abandonRate < props.clientConfig.arThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Abandonment %" value={props.abandonRate} />
                    <StatisticContainer
                        span="4" headerVar="h6" valueVar="h2" 
                        className={props.avgTalkTime <= props.clientConfig.attThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Avg. Handle Time" value={props.avgHandleTime} />
                </Row>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Row className="d-flex justify-content-around">
                    <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="darkblue" header="Vol." value={props.callVolume} />
                    <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="darkblue" header="Handled" value={props.callsHandled} />
                    <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="darkblue" header="Aband." value={props.abandonedCalls} />
                    <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="darkblue" header="Tlk. Time" value={props.totalTalkTime} />
                    <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="darkblue" header="Hld. Time" value={props.holdTime} />
                </Row>
                <Row className="d-flex justify-content-around">
                    <StatisticContainer
                        span="4" headerVar="body1" valueVar="h4" 
                        className={props.abandonRate < props.clientConfig.arThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Aband. %" value={props.abandonRate} />
                    <StatisticContainer
                        span="4" headerVar="body1" valueVar="h4" 
                        className={props.avgTalkTime <= props.clientConfig.attThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Avg. Handle" value={props.avgHandleTime} />
                </Row>
            </PhoneBreakpoint>
        </div>
    );
}

export default QueueStatsReportingContainer;