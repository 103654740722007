import React from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import Amplify from 'aws-amplify'
import {Home} from "./components/Home";
import {HistoricalView} from "./components/HistoricalView";
import {Route} from 'react-router-dom';
import {Layout} from './components/Layout';
import {AmplifyAuthenticator, AmplifySignIn} from "@aws-amplify/ui-react";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import {awsConfig} from "./config/awsConfig";

Amplify.configure(awsConfig);

function App() {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);
    
    
    return authState === AuthState.SignedIn && user ? (
        <>
            <Layout>
                <Route exact path='/' component={Home}/>
                <Route path="/history" component={HistoricalView} />
            </Layout>
        </>
    ) : (
        <>
            <AmplifyAuthenticator>
                <AmplifySignIn 
                    slot="sign-in"
                    hideSignUp={true}
                />
            </AmplifyAuthenticator>
        </>
    )
}

export default App;