import React from 'react';
import { Row, Col } from 'reactstrap';
import StatisticContainer from './StatisticContainer';
import DesktopBreakpoint from '../responsive/desktop_breakpoint'
import TabletBreakpoint from '../responsive/tablet_breakpoint'
import PhoneBreakpoint from '../responsive/phone_breakpoint'
import '../customStyles.css'
import { useState, useEffect } from 'react';

const SummaryChatStatsContainer = props => {
    const [totalChatTime] = useState(props.totalChatTime);
    
    const getFormattedTime = () => {
        return millisecondsToTimer(totalChatTime)
    };

    const millisecondsToTimer = (ms) => {
        // 1- Convert to seconds:
        var seconds = ms / 1000;

        // 2- Extract hours:
        var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour

        seconds = seconds % 3600; // seconds remaining after extracting hours

        // 3- Extract minutes:
        var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute

        // 4- Keep only seconds not extracted to minutes:
        seconds = seconds % 60;

        return ((hours > 0 ? hours + ":" : "")
            + (hours > 0 && minutes < 10 ? "0" : "") + minutes + ":"
            + (seconds < 10 ? "0" : "") + Math.floor(seconds));
    }

    return (

        <div class="queueStatsWrap">
        <DesktopBreakpoint>
            <Row className="d-flex justify-content-around">
                <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Chats Offered" value={props.chatsOffered} />
                <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Chats Handled" value={props.chatsHandled} />
                <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Missed Chats" value={props.missedChats} />
                <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Total Chat Time" value={getFormattedTime()} />
                <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Satisfaction" value={props.satisfaction} />
                <StatisticContainer headerVar="h6" valueVar="h2" span="1" className="statsRegular" headerColor="#004270" header="# Surveys" value={props.surveysTaken} />
            </Row>
        </DesktopBreakpoint>          
         <TabletBreakpoint>
                <Col>
                    <Row className="d-flex justify-content-around">
                        <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Chats Offered" value={props.chatsOffered} />
                        <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Chats Handled" value={props.chatsHandled} />
                        <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Missed Chats" value={props.missedChats} />
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <StatisticContainer headerVar="h6" valueVar="h2" span="5" className="statsRegular" headerColor="#004270" header="Satisfaction" value={props.satisfaction} />
                        <StatisticContainer headerVar="h6" valueVar="h2" span="6" className="statsRegular" headerColor="#004270" header="Total Chat Time" value={getFormattedTime()} />
                        <StatisticContainer headerVar="h6" valueVar="h2" span="5" className="statsRegular" headerColor="#004270" header="# Surveys" value={props.surveysTaken} />
                    </Row>
                </Col>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Col>
                    <Row className="d-flex justify-content-around">
                        <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="#004270" header="Offered" value={props.chatsOffered} />
                        <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="#004270" header="Handled" value={props.chatsHandled} />
                        <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="#004270" header="Missed" value={props.missedChats} />
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <StatisticContainer headerVar="body1" valueVar="h4" span="5" className="statsRegular" headerColor="#004270" header="Satisfaction" value={props.satisfaction} />
                        <StatisticContainer headerVar="body1" valueVar="h4" span="6" className="statsRegular" headerColor="#004270" header="Tot. Chat Time" value={getFormattedTime()} />
                        <StatisticContainer headerVar="body1" valueVar="h2" span="5" className="statsRegular" headerColor="#004270" header="# Surveys" value={props.surveysTaken} />
                    </Row>
                </Col>
            </PhoneBreakpoint>
    </div>
    );
};

export default SummaryChatStatsContainer;