import { useState, useEffect } from 'react';

const Timer = (props) => {
    const initialTime = props.ts 
    const [ts, setTs] = useState(new Date().valueOf());

    useEffect(() => {
        const timer = setInterval(() => {
            updateTime()
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const updateTime = () => {
        setTs(new Date().valueOf())
    }

    const getFormattedTime = () => {
        return millisecondsToTimer(ts - initialTime)
    }

    const millisecondsToTimer = (ms) => {
        // 1- Convert to seconds:
        var seconds = ms / 1000;

        // 2- Extract hours:
        var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour

        seconds = seconds % 3600; // seconds remaining after extracting hours

        // 3- Extract minutes:
        var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute

        // 4- Keep only seconds not extracted to minutes:
        seconds = seconds % 60;

        return ((hours > 0 ? hours + ":" : "")
            + (hours > 0 && minutes < 10 ? "0" : "") + minutes + ":"
            + (seconds < 10 ? "0" : "") + Math.floor(seconds));
    }

    return getFormattedTime();
}

export default Timer;