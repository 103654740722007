import React from 'react';
import { Row, Col } from 'reactstrap';
import StatisticContainer from './StatisticContainer';
import DesktopBreakpoint from '../responsive/desktop_breakpoint'
import TabletBreakpoint from '../responsive/tablet_breakpoint'
import PhoneBreakpoint from '../responsive/phone_breakpoint'
import '../customStyles.css'
import { useState, useEffect } from 'react';

const ChatStatsContainer = props => {
    const [logTime, setLogTime] = useState(props.stats.chatLogTime);
    const [timer, setTimer] = useState(0);
    const [active, setActive] = useState(props.stats.chatStatus);

    useEffect(() => {
        let interval = null;
        if (active) {
            interval = setInterval(() => {
                setTimer(timer => timer + 1000);
            }, 1000);
        } else if (!active && timer !== 0) {
            setTimer(0);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [active, timer]);

    useEffect(() => {
        setActive(props.stats.chatStatus);
    }, [props.stats.chatStatus]);

    useEffect(() => {
        setLogTime(props.stats.chatLogTime);
        setTimer(0)
    }, [props.stats.chatLogTime])

    const getFormattedTime = () => {
        return millisecondsToTimer(logTime + timer)
    };

    const millisecondsToTimer = (ms) => {
        // 1- Convert to seconds:
        var seconds = ms / 1000;

        // 2- Extract hours:
        var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour

        seconds = seconds % 3600; // seconds remaining after extracting hours

        // 3- Extract minutes:
        var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute

        // 4- Keep only seconds not extracted to minutes:
        seconds = seconds % 60;

        return ((hours > 0 ? hours + ":" : "")
            + (hours > 0 && minutes < 10 ? "0" : "") + minutes + ":"
            + (seconds < 10 ? "0" : "") + Math.floor(seconds));
    }

    return (
        <div>
            <DesktopBreakpoint>
                <Row className="d-flex justify-content-around">
                    <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Chats Offered" value={props.stats.chatsOffered} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Chats Handled" value={props.stats.chatsHandled} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Current Chats" value={props.stats.currentChats} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="2" className="statsRegular" headerColor="#004270" header="Chats In Queue" value={props.stats.chatsQueued} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Log Time" value={getFormattedTime()} />
                </Row>
            </DesktopBreakpoint>
            <TabletBreakpoint>
                <Col>
                    <Row className="d-flex justify-content-around">
                        <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Chats Offered" value={props.stats.chatsOffered} />
                        <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Chats Handled" value={props.stats.chatsHandled} />
                        <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Current Chats" value={props.stats.currentChats} />
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <StatisticContainer headerVar="h6" valueVar="h2" span="5" className="statsRegular" headerColor="#004270" header="Chats In Queue" value={props.stats.chatsQueued} />
                        <StatisticContainer headerVar="h6" valueVar="h2" span="6" className="statsRegular" headerColor="#004270" header="Log Time" value={getFormattedTime()} />
                    </Row>
                </Col>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Col>
                    <Row className="d-flex justify-content-around">
                        <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="#004270" header="Offered" value={props.stats.chatsOffered} />
                        <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="#004270" header="Handled" value={props.stats.chatsHandled} />
                        <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="#004270" header="Current" value={props.stats.currentChats} />
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <StatisticContainer headerVar="body1" valueVar="h4" span="5" className="statsRegular" headerColor="#004270" header="Chats In Queue" value={props.stats.chatsQueued} />
                        <StatisticContainer headerVar="body1" valueVar="h4" span="6" className="statsRegular" headerColor="#004270" header="Log Time" value={getFormattedTime()} />
                    </Row>
                </Col>
            </PhoneBreakpoint>
        </div>
    );
};

export default ChatStatsContainer;