import React from 'react';
import { Row, Col } from 'reactstrap'
import StatisticContainer from './StatisticContainer';
import DesktopBreakpoint from '../responsive/desktop_breakpoint'
import TabletBreakpoint from '../responsive/tablet_breakpoint'
import PhoneBreakpoint from '../responsive/phone_breakpoint'
import '../customStyles.css'

const QueueStatsContainer = props => {

    return (
        <div class="queueStatsWrap">
            <DesktopBreakpoint>
                <Row>
                    <Col lg="5">
                        <Row className="d-flex justify-content-around">
                            <StatisticContainer headerVar="h6" valueVar="h2" span="4" className="statsRegular" headerColor="#004270" header="Call Volume" value={props.callVolume} />
                            <StatisticContainer headerVar="h6" valueVar="h2" span="4" className="statsRegular" headerColor="#004270" header="Calls Handled" value={props.callsHandled} />
                            <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="#004270" header="Abandoned" value={props.abandonedCalls} />
                        </Row>
                    </Col>
                    <Col lg="7">
                        <Row>
                            <Col lg="6">
                                <Row className="d-flex justify-content-around">
                                    <StatisticContainer
                                        span="5" headerVar="h6" valueVar="h2" 
                                        className={props.serviceLevel > props.clientConfig.slThreshold ? "statsPositive" : "statsNegative"}
                                        headerColor="white"
                                        header="Service Level %" value={props.serviceLevel} />
                                    <StatisticContainer
                                        span="6" headerVar="h6" valueVar="h2" 
                                        className={props.abandonRate < props.clientConfig.arThreshold ? "statsPositive" : "statsNegative"}
                                        headerColor="white"
                                        header="Abandonment %" value={props.abandonRate} />
                                </Row>
                            </Col>
                            <Col lg="6">
                                <Row className="d-flex justify-content-around">
                                    <StatisticContainer
                                        span="5" headerVar="h6" valueVar="h2" 
                                        className={props.avgWaitTime <= props.clientConfig.awtThreshold ? "statsPositive" : "statsNegative"}
                                        headerColor="white"
                                        header="Avg. Hold Time" value={props.avgWaitTimeString} />
                                    <StatisticContainer
                                        span="6" headerVar="h6" valueVar="h2" 
                                        className={props.avgTalkTime <= props.clientConfig.attThreshold ? "statsPositive" : "statsNegative"}
                                        headerColor="white"
                                        header="Avg. Handle Time" value={props.avgTalkTimeString} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </DesktopBreakpoint>
            <TabletBreakpoint>            
                <Row className="d-flex justify-content-around">
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="darkblue" header="Call Volume" value={props.callVolume} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="darkblue" header="Calls Handled" value={props.callsHandled} />
                    <StatisticContainer headerVar="h6" valueVar="h2" span="3" className="statsRegular" headerColor="darkblue" header="Abandoned" value={props.abandonedCalls} />
                    <StatisticContainer
                        span="3" headerVar="h6" valueVar="h2" 
                        className={props.serviceLevel > props.clientConfig.slThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Service Level %" value={props.serviceLevel} />
                </Row>
                <Row className="d-flex justify-content-around">
                    <StatisticContainer
                        span="4" headerVar="h6" valueVar="h2" 
                        className={props.abandonRate < props.clientConfig.arThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Abandonment %" value={props.abandonRate} />
                    <StatisticContainer
                        span="4" headerVar="h6" valueVar="h2" 
                        className={props.avgWaitTime <= props.clientConfig.awtThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Avg. Hold Time" value={props.avgWaitTimeString} />
                    <StatisticContainer
                        span="4" headerVar="h6" valueVar="h2" 
                        className={props.avgTalkTime <= props.clientConfig.attThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Avg. Handle Time" value={props.avgTalkTimeString} />
                </Row>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Row className="d-flex justify-content-around">
                    <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="darkblue" header="Vol." value={props.callVolume} />
                    <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="darkblue" header="Handled" value={props.callsHandled} />
                    <StatisticContainer headerVar="body1" valueVar="h4" span="3" className="statsRegular" headerColor="darkblue" header="Aband." value={props.abandonedCalls} />
                    <StatisticContainer
                        span="3" headerVar="body1" valueVar="h4" 
                        className={props.serviceLevel > props.clientConfig.slThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="SL %" value={props.serviceLevel} />
                </Row>
                <Row className="d-flex justify-content-around">
                    <StatisticContainer
                        span="4" headerVar="body1" valueVar="h4" 
                        className={props.abandonRate < props.clientConfig.arThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Aband. %" value={props.abandonRate} />
                    <StatisticContainer
                        span="4" headerVar="body1" valueVar="h4" 
                        className={props.avgWaitTime <= props.clientConfig.awtThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Avg. Hold" value={props.avgWaitTimeString} />
                    <StatisticContainer
                        span="4" headerVar="body1" valueVar="h4" 
                        className={props.avgTalkTime <= props.clientConfig.attThreshold ? "statsPositive" : "statsNegative"}
                        headerColor="white"
                        header="Avg. Handle" value={props.avgTalkTimeString} />
                </Row>
            </PhoneBreakpoint>
        </div>
    );
}

export default QueueStatsContainer;