import React, { useState, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';


const DashboardEventListener = (props) => {

    const [dashboardHandler, setDashboardHandler] = useState(null);
          
    useEffect(() => {
        async function startConnection(conn) {
            await conn.start();
            console.log('SignalR dashboardHandler started.', conn.state);
        }
        if (!dashboardHandler) {
            const connection = new HubConnectionBuilder()
                .withUrl('/hub/dashboard')
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: retryContext => {
                        if (retryContext.elapsedMilliseconds < 60000) {
                            return Math.random() * 10000;
                        }  else {
                            return 30000;
                        }
                    }
                })
                .configureLogging(LogLevel.Information)
                .build();

            connection.serverTimeoutInMilliseconds = 120000;

            connection.onclose(error => {
                console.log('dashboardHandler closed due to error.  Try refreshing this page to restart.', error);
            });

            connection.onreconnecting(error => {
                console.log('dashboardHandler lost due to error. Reconnecting.', error);
            });
            connection.onreconnected(connectionId => {
                props.reconnectHandler();
                console.log('dashboardHandler re-established.  Connected with ID: ', connectionId);
            });
            startConnection(connection);
            connection.on('sendCallEvent', calls => {
                props.onCallsUpdated(calls);
            });

            connection.on('sendAgentUpdate', status => {
                props.onAgentsUpdated(status);
            });

            connection.on('callEnded', () => {
                props.onCallEnded();
            });

            connection.on('chatStatsUpdated', (chatstats) => {
                props.onChatStatsUpdated(chatstats);
            });               
            setDashboardHandler(connection);
        }
    }, [dashboardHandler, props]);

    return (
        <div/>
    );
};

export default DashboardEventListener;